<!--全景数据转换-->
<template>
  <div>
  </div>
</template>
<script>
  import template from '../../../libs/template.js'
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
    },
    watch: {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
        }
      })
      this.updateUrls()
    },
    created() {
    },
    destroyed() {
    },
    methods: {
      updateUrls() {
        var urls = {
          publicTags: template`/Api/Tags/All`,
          privateTags: template`/Api/My/Tags/All`,
          hotspotIcons: template`/Api/My/HotspotIcons`,
          fileLib: template`/Api/My/Libs`,
          filesUploadUrl: template`/Api/My/Libs/Upload`,
          panosUploadUrl: template`/Api/My/Panos/Upload`,
          fileUrl: template`/Api/My/Files`,
          panoUrl: template`/Api/My/Panos`,
          libPanosUrl: window.global.ENABLE_ELASTICSEARCH ? template`/Api/ES/My/Panos` : template`/Api/My/Panos`,
          libToursUrl: window.global.ENABLE_ELASTICSEARCH ? template`/Api/ES/My/Tours` : template`/Api/My/Tours`,
          panoLogo: template`/Api/My/Panos/Logo/${'panoId'}`,
          tourLogo: template`/Api/My/Tours/Logo/${'tourId'}`,
          audioLib: template`/Api/My/Audios`,
          weatherTypes: template`/Api/My/Panos/WeatherTypes`,
          panoCover: template`/Api/My/Panos/Cover/${'panoId'}`,
          tourCover: template`/Api/My/Tours/Cover/${'tourId'}`,
          panoSave: template`/Api/My/Panos/${'panoId'}`,
          tourSave: template`/Api/My/Tours/${'tourId'}`,
          tourCreate: template`/Api/My/Tours`,
          categorys: template`/Api/Categorys/${'module'}`,
        }
        this.getFunc('changePublicData')({ urls })
      },
    },
  }
</script>
<style scoped>
</style>
